import { createSlice } from '@reduxjs/toolkit'

import {
	fetchApprovedOperators,
	fetchClaimableRewards,
	fetchEscrowV2Data,
	fetchFeesPaid,
	fetchReferralFeesPaid,
	fetchStakingV2Data,
} from './actions'
import type { StakingState } from './types'

const ZERO_STAKE_BALANCE = {
	escrowedKwentaBalance: '0',
	stakedKwentaBalance: '0',
	totalStakedBalance: '0',
	claimableBalance: '0',
	stakedEscrowedKwentaBalance: '0',
	kwentaBalance: '0',
}

const ZERO_STAKE_BALANCE_v2 = {
	...ZERO_STAKE_BALANCE,
	feeShareBalance: '0',
}

export const ZERO_ESCROW_BALANCE = {
	totalVestable: '0',
	escrowData: [],
}

const ZERO_STAKE_DATA_v2 = {
	...ZERO_STAKE_BALANCE_v2,
	...ZERO_ESCROW_BALANCE,
}

const INITIAL_STAKE_INFO = {
	kwentaBalance: '0',
	epochPeriod: 0,
}

const INITIAL_STAKE_V2_INFO = {
	stakedResetTime: 0,
}

const ZERO_ESTIMATED_REWARDS = {
	kwenta: '0',
	arb: '0',
	opReferral: '0',
}

const ZERO_CLAIMABLE_REWARDS = {
	kwenta: '0',
	snx: '0',
	arb: '0',
	opReferral: '0',
}

const ZERO_CLAIMABLE_REWARDS_PARAMS = {
	kwentaV2: [],
	arb: [],
	opReferral: [],
}

export const ZERO_CLAIMABLE_REWARDS_DATA = {
	data: ZERO_CLAIMABLE_REWARDS,
	params: ZERO_CLAIMABLE_REWARDS_PARAMS,
}

export const ZERO_FEES_PAID = {
	kwenta: '0',
	arb: '0',
	opReferral: '0',
}

export const ZERO_REFERRAL_FEES_PAID = {
	opReferral: '0',
}

export const ZERO_STAKING_V2_DATA = {
	...ZERO_STAKE_BALANCE_v2,
	...INITIAL_STAKE_V2_INFO,
}

const ZERO_TRADING_REWARDS = {
	estimatedRewards: ZERO_ESTIMATED_REWARDS,
	claimableRewards: ZERO_CLAIMABLE_REWARDS,
	claimableRewardsParams: ZERO_CLAIMABLE_REWARDS_PARAMS,
	feesPaid: ZERO_FEES_PAID,
	referralFeesPaid: ZERO_REFERRAL_FEES_PAID,
}

export const STAKING_INITIAL_STATE: StakingState = {
	v2: {
		...ZERO_STAKE_DATA_v2,
	},
	...INITIAL_STAKE_INFO,
	...INITIAL_STAKE_V2_INFO,
	tradingRewards: ZERO_TRADING_REWARDS,
	stakingMigrationCompleted: true,
	approvedOperators: [],
}

const stakingSlice = createSlice({
	name: 'staking',
	initialState: STAKING_INITIAL_STATE,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchStakingV2Data.fulfilled, (state, action) => {
			state.v2.escrowedKwentaBalance = action.payload.escrowedKwentaBalance
			state.v2.stakedKwentaBalance = action.payload.stakedKwentaBalance
			state.v2.stakedEscrowedKwentaBalance = action.payload.stakedEscrowedKwentaBalance
			state.v2.claimableBalance = action.payload.claimableBalance
			state.v2.totalStakedBalance = action.payload.totalStakedBalance
			state.v2.feeShareBalance = action.payload.feeShareBalance
			state.stakedResetTime = action.payload.stakedResetTime
			state.kwentaBalance = action.payload.kwentaBalance
			state.epochPeriod = action.payload.epochPeriod
		})
		builder.addCase(fetchEscrowV2Data.fulfilled, (state, action) => {
			state.v2.totalVestable = action.payload.totalVestable
			state.v2.escrowData = action.payload.escrowData
		})

		builder.addCase(fetchFeesPaid.fulfilled, (state, action) => {
			state.tradingRewards.feesPaid.kwenta = action.payload.kwenta
			state.tradingRewards.feesPaid.opReferral = action.payload.opReferral
		})
		builder.addCase(fetchReferralFeesPaid.fulfilled, (state, action) => {
			state.tradingRewards.referralFeesPaid.opReferral = action.payload.opReferral
		})
		builder.addCase(fetchClaimableRewards.fulfilled, (state, action) => {
			state.tradingRewards.claimableRewards.kwenta = action.payload.data.kwenta
			state.tradingRewards.claimableRewards.snx = action.payload.data.snx
			state.tradingRewards.claimableRewards.opReferral = action.payload.data.opReferral
			state.tradingRewards.claimableRewardsParams.kwentaV2 = action.payload.params.kwentaV2
			state.tradingRewards.claimableRewardsParams.opReferral = action.payload.params.opReferral
		})
		builder.addCase(fetchApprovedOperators.fulfilled, (state, action) => {
			state.approvedOperators = action.payload.operators
		})
	},
})

export default stakingSlice.reducer
