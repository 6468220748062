import { EPOCH_CONFIGS, EPOCH_START, WEEK, ZERO_WEI } from '@kwenta/sdk/constants'
import { PerpsProvider, SnxV2NetworkIds, TransactionStatus } from '@kwenta/sdk/types'
import { getEpochDetails, getEpochPeriod } from '@kwenta/sdk/utils'
import { createAsyncThunk } from '@reduxjs/toolkit'

import { monitorAndAwaitTransaction } from 'state/app/helpers'
import { handleTransactionError, setOpenModal, setTransaction } from 'state/app/reducer'
import { fetchPerpsAccounts } from 'state/futures/actions'
import { selectSnxPerpsV2Network } from 'state/futures/common/selectors'
import { selectEpochPeriod } from 'state/staking/selectors'

import type { ThunkConfig } from 'state/types'
import { selectWallet } from 'state/wallet/selectors'
import logError from 'utils/logError'

import {
	ZERO_CLAIMABLE_REWARDS_DATA,
	ZERO_ESCROW_BALANCE,
	ZERO_FEES_PAID,
	ZERO_REFERRAL_FEES_PAID,
	ZERO_STAKING_V2_DATA,
} from './reducer'
import type {
	ClaimableRewardsData,
	EscrowBalance,
	FeesPaid,
	ReferralFeesPaid,
	StakingActionV2,
	TransferEscrowEntriesInput,
	TransferEscrowEntryInput,
} from './types'
import { serializeClaimParams, unserializeClaimParams } from './utils'

export const fetchStakingV2Data = createAsyncThunk<StakingActionV2, void, ThunkConfig>(
	'staking/fetchStakingDataV2',
	async (_, { getState, extra: { sdk } }) => {
		try {
			const wallet = selectWallet(getState())
			const epochPeriod = Math.floor((Math.floor(Date.now() / 1000) - EPOCH_START[10]) / WEEK)

			if (!wallet)
				return {
					...ZERO_STAKING_V2_DATA,
					epochPeriod,
				}

			const {
				rewardEscrowBalance,
				stakedNonEscrowedBalance,
				stakedEscrowedBalance,
				claimableBalance,
				feeShareBalance,
				totalStakedBalance,
				stakedResetTime,
				kwentaBalance,
			} = await sdk.kwentaToken.getStakingV2Data()

			return {
				escrowedKwentaBalance: rewardEscrowBalance.toString(),
				stakedKwentaBalance: stakedNonEscrowedBalance.toString(),
				stakedEscrowedKwentaBalance: stakedEscrowedBalance.toString(),
				claimableBalance: claimableBalance.toString(),
				feeShareBalance: feeShareBalance.toString(),
				totalStakedBalance: totalStakedBalance.toString(),
				stakedResetTime,
				kwentaBalance: kwentaBalance.toString(),
				epochPeriod,
			}
		} catch (err) {
			logError(err)
			throw err
		}
	}
)

export const fetchEscrowV2Data = createAsyncThunk<EscrowBalance, void, ThunkConfig>(
	'staking/fetchEscrowV2Data',
	async (_, { getState, extra: { sdk } }) => {
		try {
			const wallet = selectWallet(getState())
			if (!wallet) return ZERO_ESCROW_BALANCE

			const { escrowData, totalVestable } = await sdk.kwentaToken.getEscrowV2Data()

			return {
				escrowData: escrowData.map((e) => ({
					...e,
					vestable: e.vestable.toString(),
					amount: e.amount.toString(),
					fee: e.fee.toString(),
				})),
				totalVestable: totalVestable.toString(),
			}
		} catch (err) {
			logError(err)
			throw err
		}
	}
)

export const fetchFeesPaid = createAsyncThunk<FeesPaid, void, ThunkConfig>(
	'staking/fetchFeesPaid',
	async (_, { getState, extra: { sdk } }) => {
		try {
			const wallet = selectWallet(getState())
			if (!wallet) return ZERO_FEES_PAID

			const epochPeriod = getEpochPeriod()
			const { epochStart, epochEnd } = getEpochDetails(epochPeriod)
			const network = selectSnxPerpsV2Network(getState())
			const futuresFeePaid = await sdk.kwentaToken.getFuturesFeeForAccount(
				wallet,
				epochStart,
				epochEnd,
				network
			)

			const epochPeriodOpReferral = getEpochPeriod(EPOCH_CONFIGS.opReferral)
			const { epochStart: opReferralEpochStart, epochEnd: opReferralEpochEnd } = getEpochDetails(
				epochPeriodOpReferral,
				EPOCH_CONFIGS.opReferral
			)
			const opReferralFeePaid =
				epochPeriodOpReferral < 0
					? ZERO_WEI
					: await sdk.kwentaToken.getFuturesFeeForAccount(
							wallet,
							opReferralEpochStart,
							opReferralEpochEnd,
							network
						)

			return {
				kwenta: futuresFeePaid.toString(),
				opReferral: opReferralFeePaid.toString(),
			}
		} catch (err) {
			logError(err)
			throw err
		}
	}
)

export const fetchReferralFeesPaid = createAsyncThunk<ReferralFeesPaid, void, ThunkConfig>(
	'staking/fetchReferralFeesPaid',
	async (_, { getState, extra: { sdk } }) => {
		try {
			const wallet = selectWallet(getState())
			if (!wallet) return ZERO_REFERRAL_FEES_PAID

			const epochPeriodOpReferral = getEpochPeriod(EPOCH_CONFIGS.opReferral)
			const { epochStart: opReferralEpochStart, epochEnd: opReferralEpochEnd } = getEpochDetails(
				epochPeriodOpReferral,
				EPOCH_CONFIGS.opReferral
			)

			const opReferralFeePaid =
				epochPeriodOpReferral < 0
					? ZERO_WEI
					: await sdk.referrals.getReferralFeesPaid(
							wallet,
							opReferralEpochStart,
							opReferralEpochEnd
						)

			return {
				opReferral: opReferralFeePaid.toString(),
			}
		} catch (err) {
			logError(err)
			throw err
		}
	}
)

export const fetchStakeMigrateData = createAsyncThunk<void, void, ThunkConfig>(
	'staking/fetchMigrateData',
	async (_, { dispatch }) => {
		dispatch(fetchPerpsAccounts({ providers: [PerpsProvider.SNX_V2_OP] }))
		dispatch(fetchStakingV2Data())
		dispatch(fetchEscrowV2Data())
		dispatch(fetchFeesPaid())
		dispatch(fetchReferralFeesPaid())
		dispatch(fetchApprovedOperators())
	}
)

export const vestEscrowedRewards = createAsyncThunk<void, number[], ThunkConfig>(
	'staking/vestEscrowedRewards',
	async (ids, { dispatch, getState, extra: { sdk } }) => {
		const wallet = selectWallet(getState())

		try {
			if (!wallet) throw new Error('Wallet not connected')

			dispatch(
				setTransaction({
					chainId: SnxV2NetworkIds.OPTIMISM_MAINNET,
					status: TransactionStatus.AwaitingExecution,
					type: 'vest_escrowed_rewards_v2',
					hash: null,
				})
			)

			if (ids.length > 0) {
				const tx = await sdk.kwentaToken.vestTokenV2(ids)
				await monitorAndAwaitTransaction(SnxV2NetworkIds.OPTIMISM_MAINNET, dispatch, tx)
				dispatch(fetchStakingV2Data())
				dispatch(fetchEscrowV2Data())
				dispatch(setOpenModal(null))
			}
		} catch (err) {
			logError(err)
			dispatch(handleTransactionError({ message: err.message }))
			throw err
		}
	}
)

export const claimStakingRewardsV2 = createAsyncThunk<void, void, ThunkConfig>(
	'staking/claimStakingRewardsV2',
	async (_, { dispatch, getState, extra: { sdk } }) => {
		const wallet = selectWallet(getState())
		try {
			if (!wallet) throw new Error('Wallet not connected')

			dispatch(
				setTransaction({
					chainId: SnxV2NetworkIds.OPTIMISM_MAINNET,
					status: TransactionStatus.AwaitingExecution,
					type: 'claim_staking_rewards_v2',
					hash: null,
				})
			)

			const tx = await sdk.kwentaToken.claimStakingRewardsV2()
			await monitorAndAwaitTransaction(SnxV2NetworkIds.OPTIMISM_MAINNET, dispatch, tx)
			dispatch(fetchStakeMigrateData())
		} catch (err) {
			logError(err)
			dispatch(handleTransactionError({ message: err.message }))
			throw err
		}
	}
)

export const bulkTransferEscrowEntries = createAsyncThunk<
	void,
	TransferEscrowEntriesInput,
	ThunkConfig
>(
	'staking/bulkTransferEscrowEntries',
	async ({ entries, recipient }, { dispatch, getState, extra: { sdk } }) => {
		const wallet = selectWallet(getState())

		try {
			if (!wallet) throw new Error('Wallet not connected')
			dispatch(
				setTransaction({
					chainId: SnxV2NetworkIds.OPTIMISM_MAINNET,
					status: TransactionStatus.AwaitingExecution,
					type: 'transfer_escrow_entries',
					hash: null,
				})
			)

			const tx = await sdk.kwentaToken.bulkTransferFrom(wallet, recipient, entries)
			await monitorAndAwaitTransaction(SnxV2NetworkIds.OPTIMISM_MAINNET, dispatch, tx)
			dispatch(fetchStakingV2Data())
			dispatch(fetchEscrowV2Data())
			dispatch(setOpenModal(null))
		} catch (err) {
			logError(err)
			dispatch(handleTransactionError({ message: err.message }))
			throw err
		}
	}
)

export const transferEscrowEntry = createAsyncThunk<void, TransferEscrowEntryInput, ThunkConfig>(
	'staking/transferEscrowEntry',
	async ({ entry, recipient }, { dispatch, getState, extra: { sdk } }) => {
		const wallet = selectWallet(getState())

		try {
			if (!wallet) throw new Error('Wallet not connected')

			dispatch(
				setTransaction({
					chainId: SnxV2NetworkIds.OPTIMISM_MAINNET,
					status: TransactionStatus.AwaitingExecution,
					type: 'transfer_escrow_entry',
					hash: null,
				})
			)

			const tx = await sdk.kwentaToken.transferFrom(wallet, recipient, entry)
			await monitorAndAwaitTransaction(SnxV2NetworkIds.OPTIMISM_MAINNET, dispatch, tx)
			dispatch(fetchStakingV2Data())
			dispatch(fetchEscrowV2Data())
			dispatch(setOpenModal(null))
		} catch (err) {
			logError(err)
			dispatch(handleTransactionError({ message: err.message }))
			throw err
		}
	}
)

export const fetchClaimableRewards = createAsyncThunk<ClaimableRewardsData, void, ThunkConfig>(
	'staking/fetchClaimableRewards',
	async (_, { getState, extra: { sdk } }) => {
		try {
			const wallet = selectWallet(getState())
			const epochPeriod = selectEpochPeriod(getState())

			if (!wallet) return ZERO_CLAIMABLE_REWARDS_DATA

			const { totalRewards: kwentaRewardsV2, claimableRewards: kwentaRewardsV2Params } =
				await sdk.kwentaToken.getClaimableKwentaRewards(epochPeriod)

			const { totalRewards: opReferralRewards, claimableRewards: opReferralRewardsParams } =
				await sdk.kwentaToken.getClaimableOpReferralRewards()

			return {
				data: {
					kwenta: kwentaRewardsV2.toString(),
					snx: '0',
					opReferral: opReferralRewards.toString(),
				},
				params: {
					kwentaV2: serializeClaimParams(kwentaRewardsV2Params),
					opReferral: serializeClaimParams(opReferralRewardsParams),
				},
			}
		} catch (err) {
			logError(err)
			throw err
		}
	}
)

export const claimMultipleKwentaRewards = createAsyncThunk<void, void, ThunkConfig>(
	'staking/claimMultipleKwentaRewards',
	async (_, { dispatch, getState, extra: { sdk } }) => {
		const {
			staking: { tradingRewards },
		} = getState()

		try {
			dispatch(
				setTransaction({
					chainId: SnxV2NetworkIds.OPTIMISM_MAINNET,
					status: TransactionStatus.AwaitingExecution,
					type: 'claim_kwenta_rewards',
					hash: null,
				})
			)

			const tx = await sdk.kwentaToken.claimMultipleAllRewards([
				unserializeClaimParams(tradingRewards.claimableRewardsParams.kwentaV2),
			])

			await monitorAndAwaitTransaction(SnxV2NetworkIds.OPTIMISM_MAINNET, dispatch, tx)
			dispatch(fetchStakeMigrateData())
		} catch (err) {
			dispatch(handleTransactionError({ message: err.message }))
			throw err
		}
	}
)

export const claimMultipleOpReferralRewards = createAsyncThunk<void, void, ThunkConfig>(
	'staking/claimMultipleOpReferralRewards',
	async (_, { dispatch, getState, extra: { sdk } }) => {
		const {
			staking: { tradingRewards },
		} = getState()

		try {
			dispatch(
				setTransaction({
					chainId: SnxV2NetworkIds.OPTIMISM_MAINNET,
					status: TransactionStatus.AwaitingExecution,
					type: 'claim_op_referral_rewards',
					hash: null,
				})
			)
			const tx = await sdk.kwentaToken.claimOpReferralRewards(
				unserializeClaimParams(tradingRewards.claimableRewardsParams.opReferral)
			)

			await monitorAndAwaitTransaction(SnxV2NetworkIds.OPTIMISM_MAINNET, dispatch, tx)
			dispatch(fetchFeesPaid())
			dispatch(fetchReferralFeesPaid())
		} catch (err) {
			dispatch(handleTransactionError({ message: err.message }))
			throw err
		}
	}
)

export const unstakeEscrowV2 = createAsyncThunk<void, bigint, ThunkConfig>(
	'staking/unstakeEscrowV2',
	async (amount, { dispatch, getState, extra: { sdk } }) => {
		const wallet = selectWallet(getState())

		try {
			if (!wallet) throw new Error('Wallet not connected')

			dispatch(
				setTransaction({
					chainId: SnxV2NetworkIds.OPTIMISM_MAINNET,
					status: TransactionStatus.AwaitingExecution,
					type: 'unstake_escrow_v2',
					hash: null,
				})
			)

			const tx = await sdk.kwentaToken.unstakeEscrowedKwentaV2(amount)
			await monitorAndAwaitTransaction(SnxV2NetworkIds.OPTIMISM_MAINNET, dispatch, tx)
			dispatch(fetchStakeMigrateData())
		} catch (err) {
			logError(err)
			dispatch(handleTransactionError({ message: err.message }))
			throw err
		}
	}
)

export const unstakeKwentaV2 = createAsyncThunk<void, bigint, ThunkConfig>(
	'staking/unstakeKwentaV2',
	async (amount, { dispatch, getState, extra: { sdk } }) => {
		const wallet = selectWallet(getState())

		try {
			if (!wallet) throw new Error('Wallet not connected')

			dispatch(
				setTransaction({
					chainId: SnxV2NetworkIds.OPTIMISM_MAINNET,
					status: TransactionStatus.AwaitingExecution,
					type: 'unstake_kwenta_v2',
					hash: null,
				})
			)

			const tx = await sdk.kwentaToken.unstakeKwentaV2(amount)
			await monitorAndAwaitTransaction(SnxV2NetworkIds.OPTIMISM_MAINNET, dispatch, tx)
			dispatch(fetchStakeMigrateData())
		} catch (err) {
			logError(err)
			dispatch(handleTransactionError({ message: err.message }))
			throw err
		}
	}
)

export const fetchApprovedOperators = createAsyncThunk<{ operators: string[] }, void, ThunkConfig>(
	'staking/fetchApprovedOperators',
	async (_, { getState, extra: { sdk } }) => {
		const wallet = selectWallet(getState())
		try {
			if (!wallet) return { operators: [] }

			const operatorsApprovalTxns = await sdk.kwentaToken.getApprovedOperators()
			const operatorStatus: { [key: string]: boolean } = {}
			for (const txn of operatorsApprovalTxns) {
				if (operatorStatus[txn.operator] === undefined) {
					operatorStatus[txn.operator] = txn.approved
				}
			}
			const operators = Object.keys(operatorStatus)
				.filter((operator) => operatorStatus[operator])
				.map((operator) => operator.toLowerCase())

			return {
				operators,
			}
		} catch (err) {
			logError(err)
			throw err
		}
	}
)
