import { type DepositableV3Assets, DepositableV3AssetsBase } from '@kwenta/sdk/constants'
import { PerpsProvider } from '@kwenta/sdk/types'

// TODO: This is very simplistic as does not fully take into consideration the liquidation reward
// However it is not possible to calculate without new methods being exposed on the contract
export const REQUIRED_MARGIN_RATIO = 0.03

export const SNX_V3_DEPOSIT_OPTIONS: Partial<Record<PerpsProvider, DepositableV3Assets[]>> = {
	[PerpsProvider.SNX_V3_BASE]: Object.keys(DepositableV3AssetsBase) as DepositableV3AssetsBase[],
}
